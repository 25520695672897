export const appStore = {
    state: {
        menu: undefined,
        currentCategoryAlias: null,
        banners: undefined,
        pages: undefined,
        showAuthForm: false,
        showSignUpForm: false,
        storages: null
    },
    getters: {
        categories(state){
            if(state.menu === undefined){
                return [];
            }
            let list = state.menu.categories
            var map = {}, node, roots = [], i;
            for (i = 0; i < list.length; i += 1) {
                map[list[i].alias] = i;
                list[i].childCategories = [];
            }
            for (i = 0; i < list.length; i += 1) {
                node = list[i];
                if (node.parentCategory !== null) {
                    list[map[node.parentCategory]].childCategories.push(node);
                } else {
                    roots.push(node);
                }
            }
            return roots;
        },
        currentCategory(state, getters){
            if(state.menu === undefined || !state.currentCategoryAlias){
                return false;
            }
            let stack = [];
            getters.categories.forEach(node => stack.push(node))
            while(stack.length){
                const node = stack.pop()
                if(node.alias === state.currentCategoryAlias){
                    return node
                }
                if(node.childCategories.length){
                    node.childCategories.forEach(child => stack.push(child))
                }
            }

            return false;
        },
        products(state){
            if(state.menu === undefined){
                return [];
            }

            return state.menu.products
        },
        newProducts(state){
            if(state.menu === undefined){
                return [];
            }
            return state.menu.products.filter(item => item.productMark.find(item => item.name === 'New'))
        },
        dimensionalGrids(state){
            if(state.menu === undefined){
                return [];
            }
            return state.menu.dimensionalGrids
        },
        discountProducts(state){
            if(state.menu === undefined){
                return [];
            }
            return state.menu.products.filter(item => item.productMark.find(item => item.name === 'Discount'))
        },
        howDetectSizePage(state){
            if(state.pages === undefined){
                return null;
            }
            return state.pages.filter(item => item.name === 'Как определить размер?')[0]
        }
    },
    mutations: {
        setMenu(state, menu){
            state.menu = menu
        },
        setCurrentCategoryAlias(state, categoryAlias){
            state.currentCategoryAlias = categoryAlias
        },
        setBanners(state, banners){
            state.banners = banners
        },
        setShowAuthForm(state, status){
            state.showAuthForm = status
        },
        setShowSignUpForm(state, status){
            state.showSignUpForm = status
        },
        setStorages(state, storages){
            state.storages = storages
        },
        setPages(state, pages){
            state.pages = pages
        },
    },
    actions: {
        setMenu(context, menu){
            context.commit('setMenu', menu)
        },
        setCurrentCategoryAlias(context,categoryAlias){
            context.commit('setCurrentCategoryAlias', categoryAlias)
        },
        setBanners(context, banners){
            context.commit('setBanners', banners)
        },
        setShowAuthForm(context, status){
            context.commit('setShowAuthForm', status)
        },
        setShowSignUpForm(context, status){
            context.commit('setShowSignUpForm', status)
        },
        setStorages(context, storages){
            context.commit('setStorages', storages)
        },
        setPages(context, pages){
            context.commit('setPages', pages)
        },
    },
    namespaced: true
}