<template>
  <Modal v-if="howDetectSizePage" wrapperClass="modal-how-detect-size" :title="howDetectSizePage.name" v-model="howDetectSize"
         :modal-class="'modal modal-xl'">
    <div v-html="howDetectSizePage.content"></div>
  </Modal>
  <div class="product-wrapper">
    <div class="product-wrapper__top">
      <div class="product-wrapper__top__left flex gap-4 w-[54%]">
        <div class="border border-grey shrink-0 p-4 bg-white">
          <swiper @swiper="setThumbsSwiper" :slidesPerView="6" space-between="15" :direction="'vertical'" :modules="modules" class="thumbs-product-swiper h-full">
            <swiper-slide v-if="product.video && product.video.url">
                <div class="h-full flex items-center justify-center">
                  <img class="h-full" v-if="product.videoPicture.url" :src="product.videoPicture.url" alt="">
                  <video v-else muted loop>
                    <source :src="product.video.url" type="video/mpa4">
                  </video>
                </div>
            </swiper-slide>
            <swiper-slide v-for="picture in pictures">
              <div class="w-[120px] h-full">
                <img class="h-full object-cover" :src="picture.url" alt="">
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <swiper @init="afterSwiper" @slideChange="startVideo" class="main-product-swiper border border-grey" :thumbs="{ swiper: thumbsSwiper }" :modules="modules">
          <swiper-slide v-if="product.video && product.video.url">
            <div class="h-full flex items-center justify-center">
              <video class="object-contain" muted loop>
                <source :src="product.video.url" type="video/mp4">
              </video>
            </div>
          </swiper-slide>
          <swiper-slide v-for="picture in pictures" class="text-center">
            <img class="h-full object-cover inline" :src="picture.url" alt="">
          </swiper-slide>
        </swiper>
      </div>
      <div class="product-wrapper__top__right border border-grey w-[46%]">
        <div>
          <div class="product-brand-info h-[60px]">
            <div v-if="product.brand" class="h-full">
              <span class="info-title">Бренд:</span>
              <div class="flex justify-center items-center h-full p-1">
                <img class="max-h-full" :src="product.brand.picture" alt="">
              </div>
            </div>
            <div>
<!--              <a href="#">Написать отзыв/вопрос</a>-->
            </div>
            <div>
              <a v-if="product.link_to_youtube" :href="product.link_to_youtube">YouTube</a>
            </div>
            <div>
              <a v-if="howDetectSizePage" class="hover:underline text-blue-600" @click="toggleHowDetectSize" href="#">Как определить размер?</a>
            </div>
          </div>
          <div class="product-name-material-info">
            <div>
              <span class="info-title">Модель:</span>
              <p v-html="product.name"></p>
            </div>
            <div>
              <p>Материалы:</p>
              <ol>
                <li v-for="material in product.materials">{{material.name}}</li>
              </ol>
            </div>
          </div>
          <div class="product-price-delivery-info">
            <div>
              <span class="info-title">Цена:</span>
              <p>{{price + ' ₽'}}</p>
            </div>
            <div>
              <p>Доставка</p>
              <div>Цена: 630 р. Срок: 8 - 12 дней</div>
              <div>Цена: 279.17 р. Срок: 3 дней</div>
            </div>
          </div>
          <div class="product-buy-block">
            <div>
              <p>Расцветка</p>
              <img :src="imageCurrentColor" alt="">
            </div>
            <div>
              <p>Размер</p>
              <p>{{size ? size.name : '-'}}</p>
            </div>
            <div>
              <p>Цена</p>
              <p>{{price + ' ₽'}}</p>
            </div>
            <div>
              <p>Кол-во</p>
              <div>
                <p @click="decrementCount">-</p>
                <p>{{count}}</p>
                <p @click="incrementCount">+</p>
              </div>
            </div>
            <div>
              <p>Сумма</p>
              <p>{{sum}}</p>
            </div>
            <div>
              <button @click="addToCart">Добавить в корзину</button>
              <button>Купить сейчас</button>
            </div>
          </div>
          <div class="product-colors-block">
            <color-carousel-grid-single @chooseColor="setColor" :count-slide="5" :colors = product.productColors></color-carousel-grid-single>
          </div>
          <div class="product-size-grid">
            <size-grid @chooseSize="setSize" :color-id="colorId" :number-of-sizes="product.variations" :color-image="imageCurrentColor" :dimensionalGridId="product.dimensional_grid_id"></size-grid>
          </div>
          <div class="related-product-block">
            <p>Сопутствующие товары:</p>
            <div>
              <router-link v-for="relatedProduct in product.relatedProducts" :to="'/catalog/clothes/' + relatedProduct.alias">
                <img :src="relatedProduct.picture" alt="">
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="text-xl tracking-widest text-description font-normal bg-white inline-block px-6 py-2 rounded border border-grey border-b-white mb-[-2px] relative z-10">Описание</h3>
      <div @click="toggle($event); zoom($event)" class="flex justify-between gap-4">
        <div class="bg-white border border-grey shrink-0 w-1/2" v-html="product.left_desc"></div>
        <div class="bg-white border border-grey" v-html="product.right_desc"></div>
      </div>
    </div>
  </div>
</template>
<script>
import SizeGrid from "@/components/SizeGrid.vue";
import {mapGetters} from "vuex";
import Store from "@/store";
import {useToast} from "vue-toastification";
import {reactive} from "vue";
const toast = useToast()
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import ColorCarouselGridSingle from "@/components/ColorCarouselGridSingle.vue";

export default {
  name: "ProductCard",
  components: {
    ColorCarouselGridSingle,
    SizeGrid,
    Swiper,
    SwiperSlide
  },
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    currentSlide: 0,
    sizeId: null,
    sizeIndex: null,
    colorId: 0,
    count: 1,
    thumbsSwiper: null,
    modules: [FreeMode, Navigation, Thumbs],
    howDetectSize: false
  }),
  computed: {
    ...mapGetters('app', [
      'dimensionalGrids',
      'howDetectSizePage'
    ]),
    productColor(){
      if(this.colorId !== 0){
        return this.product.productColors.find(item => item.color_id === this.colorId)
      }
      return this.product.productColors.find(item => item.is_default === true)
    },
    imageCurrentColor(){
      return this.productColor.pictureColor
    },
    price(){
      if(Object.entries(this.product).length === 0){
        return 0
      }
      return new Intl.NumberFormat().format(this.product.price)
    },
    sum(){
      if(this.price === 0){
        return 0;
      }
      let clearPrice = this.price.replace(/\D/g,'')
      return new Intl.NumberFormat().format(clearPrice * this.count) + ' ₽'
    },
    size(){
      return this.dimensionalGrids[this.product.dimensional_grid_id].sizes[this.sizeIndex];
    },
    pictures(){
      let pictures = reactive(this.product.pictures.slice())
      pictures.unshift({"url": this.product.productColors.find(item => item.is_default === true).pictureProduct})
      return pictures
    }
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
    setSize(sizeId, sizeIndex){
      this.sizeId = sizeId
      this.sizeIndex = sizeIndex
    },
    setColor(colorId){
      this.colorId = Number(colorId)
      this.currentSlide = 0
      this.pictures[0].url = this.productColor.pictureProduct
    },
    incrementCount(){
      this.count++
    },
    decrementCount(){
      if(this.count > 1){
        this.count--
      }
    },
    addToCart(){
      if(this.sizeId === null){
        toast.warning("Выберите размер!")
        return
      }
      if(this.colorId === null){
        toast.warning("Выберите цвет!")
        return
      }
      let product = {
        id: this.product.id,
        productColor: this.productColor,
        name: this.product.name,
        size: this.size,
        price: this.price,
        count: this.count,
        sum: this.sum,
        weight: this.product.weight
      }
      Store.dispatch('cart/addProduct', product)
      toast.success("Товар успешно добавлен в корзину")
    },
    setThumbsSwiper(swiper){
      this.thumbsSwiper = swiper;
    },
    toggle(e){
      let link = null;
      if(e.target.classList.contains('toggle')){
        link = e.target
      }else if(e.target.parentNode.classList.contains('toggle')){
        link = e.target.parentNode
      }else {
        return
      }
      let toggleClass = link.getAttribute('data-target')
      let toggleElement = document.getElementsByClassName(toggleClass)[0]
      toggleElement.classList.toggle('show-anim')
    },
    zoom(e){
      if(e.target.classList.contains('zoomIn')){
        e.target.remove()
      }
      else if(e.target.classList.contains('zoom')){
        let baseImage = e.target;
        let parent = baseImage.parentElement
        let clone = baseImage.cloneNode(false)
        clone.classList.add('zoomIn')
        parent.classList.add('relative')
        parent.classList.add('w-0')
        parent.appendChild(clone)
      }
    },
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // получить положение курсора мыши при запуске:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // вызов функции при каждом перемещении курсора:
      document.onmousemove = elementDrag;
    },
    elementDrag(e){
      e = e || window.event;
      e.preventDefault();
      let elmnt = e.target
      // вычислить новую позицию курсора:
      let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // установите новое положение элемента:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    },
    closeDragElement(){
      // остановка перемещения при отпускании кнопки мыши:
      document.onmouseup = null;
      document.onmousemove = null;
    },
    toggleHowDetectSize(){
      this.howDetectSize = !this.howDetectSize
    },
    startVideo(e){
      let video = document.querySelectorAll(".swiper-slide-active video")[0];
      if(video === undefined){
        return
      }
      video.currentTime = 0;
      video.play()
    },
    afterSwiper(swiper){
      if(this.product.video){
        swiper.slideTo(1)
      }
    }
  }
}
</script>
<style lang="scss">
.show-anim{
  opacity: 1 !important;
  height: auto !important;
  transition: opacity 1s ease-out !important;
}
.zoomIn{
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  width: 50vw !important;
  max-width: 50vw !important;
  height: auto !important;
  transition: width 1s ease-out !important;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  cursor: zoom-out !important;
}
</style>
<style scoped lang="scss">

.product-wrapper {
  padding: 1vh 1em 1.5vh 1em;

  .product-wrapper__top {
    display: flex;
    gap: 1em;
    width: 100%;
    justify-content: space-between;

    > div {
      height: 82vh;
      flex-grow: 1;
    }

    .product-wrapper__top__right {
      > div{
        width: 100%;
        height: 100%;
        margin: auto;
        background-color: #fff;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        >div{
          height: 100%;
        }
      }

      .info-title{
        font-weight: normal !important;
        font-style: italic;
        font-size: 16px;
        display: inline-block;
        color: #989898;
        width: 70px;
        text-align: start;
      }

      .product-brand-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;

        > div {
          &:nth-child(1) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              margin-right: 20px;
            }
          }
        }
      }

      .product-name-material-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;

        > div {
          height: 100%;

          &:nth-child(1) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 60%;

            p {
              margin-right: 20px;
              font-size: 20.8px;
            }
          }
          &:nth-child(2){
            border-left: 1px solid #dddddd;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px;
            width: 40%;

            p{
              font-style: italic;
              color: #939393;
              margin-bottom: 0;
              font-size: 13px;
            }

            ol{
              font-weight: 600;
              margin-left: 10px;
              font-size: 12px;
              margin-bottom: 5px;

              li{
                text-align: start;
              }
            }
          }
        }
      }

      .product-price-delivery-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        >div{
          height: 100%;

          &:nth-child(1){
            display: flex;
            width: 60%;
            align-items: center;

            p{
              font-size: 30px;
              font-weight: 700;
              margin-bottom: 5px;
              color: #FF471B;
            }
          }

          &:nth-child(2){
            display: flex;
            border-left: 1px solid #dddddd;
            flex-direction: column;
            width: 40%;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px;

            p{
              font-style: italic;
              color: #939393;
              margin-bottom: 0;
              font-size: 13px;
            }
          }
        }
      }

      .product-buy-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F5F5;
        padding: 10px 0 10px 15px;
        height: 100px;

        > div {
          > p{
            &:first-child{
              font-size: 14px;
              font-style: italic;
              text-align: center;
              margin-bottom: 10px;
            }
            &:nth-child(2){
              font-size: 20px;
              font-weight: bold;
            }
          }
          &:first-child{
            img{
              height: 35px;
              width: 100%;
              object-fit: cover;
            }
          }
          &:nth-child(4){
            div{
              display: flex;
              justify-content: space-between;
              align-items: center;

              p{
                font-weight: bold;
                font-size: 20px;
                width: 20px;
                user-select: none;

                &:first-child{
                  cursor: pointer;
                }
                &:last-child{
                  position: relative;
                  top: -2px;
                  cursor: pointer;
                }
              }
            }
          }
          &:last-child{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            >button{
              width: 100%;
              cursor: pointer;

              &:first-child{
                background: linear-gradient(to top, #DA3C26, #E58754);
                color: white;
                font-size: 18px;
                border: none;
                padding: 7px 25px;
                margin-top: 5px;
                margin-bottom: 10px;
                border-radius: 10px;
                box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.75);
                outline: none;

                &:hover{
                  background: linear-gradient(to bottom, #DA3C26, #E58754);
                  box-shadow: 0 0 2px 2px rgba(219, 160, 74, 0.8);
                }
              }

              &:last-child{
                background: linear-gradient(to top, #E19250, #ECAE5B);
                color: white;
                border-radius: 10px;
                font-size: 18px;
                border: none;
                padding: 7px 15px;
                box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.75);
                outline: none;

                &:hover{
                  background: linear-gradient(to bottom, #E19250, #ECAE5B);
                  box-shadow: 0 0 2px 2px rgba(219, 160, 74, 0.8);
                }
              }
            }
          }
        }
      }

      .product-colors-block{
        padding: 15px 0;
      }

      .related-product-block{
        min-height: 227px;
        padding: 10px 0;

        p{
          color: #989898;
          font-style: italic;
          text-align: start;
          padding-bottom: 10px;
        }

        div{
          display: flex;
          gap: 10px;

          img {
            height: 16vh;
          }
        }
      }
    }

    .product-wrapper__top__left{
      .thumbs-product-swiper{
        background-color: #ffffff;

        .swiper-slide{
          background: linear-gradient(to bottom right, #d8d5dc, #f8f8fa);
        }

        .swiper-slide-thumb-active{
          border: 3px solid rgba(219, 160, 74, 0.8);
        }
      }
      .main-product-swiper{
        background: linear-gradient(to bottom right, #d8d5dc, #f8f8fa);
      }
    }
  }
}
</style>